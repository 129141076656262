import React, { FC } from 'react';
import IconProps from './IconProps';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const GuageIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg
      data-cy="guage-icon"
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_567_21068)">
        <path
          d="M20.3802 8.56914L19.1502 10.4191C19.7433 11.6022 20.0337 12.914 19.9954 14.2369C19.957 15.5598 19.591 16.8525 18.9302 17.9991H5.07017C4.21134 16.5092 3.85547 14.7823 4.05531 13.0742C4.25516 11.3662 5.00008 9.76797 6.17965 8.51657C7.35922 7.26517 8.91064 6.42719 10.6039 6.12684C12.2972 5.82649 14.0422 6.07978 15.5802 6.84914L17.4302 5.61914C15.5467 4.41136 13.3125 3.87015 11.0851 4.08208C8.85763 4.29401 6.76562 5.24684 5.14367 6.78816C3.52171 8.32947 2.46353 10.3702 2.13839 12.584C1.81325 14.7977 2.23992 17.0565 3.35017 18.9991C3.52468 19.3014 3.77526 19.5528 4.077 19.7282C4.37875 19.9036 4.72115 19.997 5.07017 19.9991H18.9202C19.2726 20.0005 19.6191 19.9088 19.9247 19.7332C20.2302 19.5576 20.484 19.3044 20.6602 18.9991C21.5815 17.4031 22.0439 15.5834 21.9963 13.7411C21.9486 11.8988 21.3928 10.1055 20.3902 8.55914L20.3802 8.56914Z"
          fill="currentColor"
        />
        <path
          d="M10.59 15.4099C10.7757 15.5959 10.9963 15.7434 11.2391 15.844C11.4819 15.9447 11.7422 15.9965 12.005 15.9965C12.2678 15.9965 12.5281 15.9447 12.7709 15.844C13.0137 15.7434 13.2342 15.5959 13.42 15.4099L19.08 6.91992L10.59 12.5799C10.404 12.7657 10.2565 12.9862 10.1559 13.229C10.0552 13.4718 10.0034 13.7321 10.0034 13.9949C10.0034 14.2578 10.0552 14.518 10.1559 14.7608C10.2565 15.0036 10.404 15.2242 10.59 15.4099Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_567_21068">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GuageIcon;
